<template>
    <div class="content">
        <div class="left-content">
            <el-menu
            class="el-menu-vertical-demo"
            default-openeds="[1,2,3,4]"
        >
            <el-sub-menu index="1">
                <template #title>
                    <span>常见问题</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="1-1" @click="toRoute(1,'售后问题')">售后问题</el-menu-item>
                    <el-menu-item index="1-2" @click="toRoute(2,'支付问题')">支付问题</el-menu-item>
                </el-menu-item-group>
            </el-sub-menu>
            <el-sub-menu index="2">
                <template #title>
                    <span>新手指南</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="2-1" @click="toRoute(3,'购物流程')">购物流程</el-menu-item>
                    <el-menu-item index="2-2" @click="toRoute(4,'支付方式')">支付方式</el-menu-item>
                </el-menu-item-group>
            </el-sub-menu>

            <el-sub-menu index="3">
                <template #title>
                    <span>售后服务</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="3-1"  @click="toRoute(5,'退货政策')">退货政策</el-menu-item>
                    <el-menu-item index="3-2"  @click="toRoute(6,'取消订单')">取消订单</el-menu-item>
                    <el-menu-item index="3-3"  @click="toRoute(7,'退款流程')">退款流程</el-menu-item>
                    <el-menu-item index="3-4"  @click="toRoute(8,'退款说明')">退款说明</el-menu-item>
                </el-menu-item-group>
            </el-sub-menu>

            <el-sub-menu index="4">
                <template #title>
                    <span>关于我们</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item index="4-1" @click="toRoute(9,'关于我们')">关于我们</el-menu-item>
                    <el-menu-item index="4-2" @click="toRoute(10,'联系我们')">联系我们</el-menu-item>
                </el-menu-item-group>
            </el-sub-menu>
            
        </el-menu>
        </div>

        <div class="right-content">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import {useRouter} from 'vue-router'
export default {
    setup(){
        const router = useRouter()
        const toRoute = (type,info) =>{
            if(type ==1){
                router.push({
                    path:'/serve/after_sale',
                    query:{
                        info:info
                    }
                })
            }
            else if(type ==2){
                router.push({
                    path:'/serve/pay_question',
                    query:{
                        info:info
                    }
                })
            }
            else if(type ==3){
                router.push({
                    path:'/serve/shopping',
                    query:{
                        info:info
                    }
                })
            }
            else if(type ==4){
                router.push({
                    path:'/serve/paytype',
                    query:{
                        info:info
                    }
                })
            }
            else if(type ==5 ){
                router.push({
                    path:'/serve/thpolicy',
                    query:{
                        info:info
                    }
                })
            }
            else if(type == 6 ){
                router.push({
                    path:'/serve/cancel',
                    query:{
                        info:info
                    }
                })
            }
            else if(type ==7){
                router.push({
                    path:'/serve/thprocess',
                    query:{
                        info:info
                    }
                })
            }
            else if(type ==8){
                router.push({
                    path:'/serve/tkinfo',
                    query:{
                        info:info
                    }
                })
            }
            else if(type ==9){
                router.push({
                    path:'/serve/aboutus',
                    query:{
                        info:info
                    }
                })
            }
            else if(type ==10){
                router.push({
                    path:'/serve/contractus',
                    query:{
                        info:info
                    }
                })
            }
        }
        return {
            toRoute
        }
    }
}
</script>
<style scoped lang="scss">
    .content{
        width: 1200px;
        min-height: 800px;
        margin: 70px auto 0;
        padding-bottom: 70px;
        display: flex;
        .left-content{
            width: 220px;
            height: 100%;
            margin-right: 20px;
            .el-menu-vertical-demo{
                border: 1px solid #eee;
            }
        }
        .right-content{
            width: 960px;
            border:1px solid #eee;
        }
    }
    ::v-deep .el-sub-menu__title{
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
    }
    ::v-deep .el-menu-item-group__title{
        padding: 0;
    }
</style>